import router from '@/router';
import axios from 'axios'
// import {  Message } from 'element-ui'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    // const cookie="JSESSIONID="+ localStorage.getItem('cookie');
    // config.headers['cookie'] = cookie;
    // document.cookie =cookie;
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if(res.loginStatus===1){
      router.push({path:'/login'})
    }else {
      return res
    }
    
    // if the custom code is not 20000, it is judged as an error.
    /* if (res.code !== 20000) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    } */
  },
  error => {
    console.log('err534543534543' + JSON.stringify(error)) // for debug    
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
