import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui' //引入js
import 'element-ui/lib/theme-chalk/index.css'//引入css
import axios from 'axios'
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.ExportExcel2 = function (list, tHeader1, tHeader2, merges, filterVal, title) {
  // 导出数据
  this.list = list

  // 表头数据对应名字
  this.filterVal = filterVal
  // 数据方法
  this.formatJson = function (filterVal, jsonData) {
    return jsonData.map(v => filterVal.map(j => v[j]))
  }
  // 导出文件的方法
  this.export2Excel2 = function () {
    require.ensure([], () => {
      const { export_json_to_excel } = require('@/Excel/Export2Excel')

      const filterVal = this.filterVal
      const list = this.list
      const data = this.formatJson(filterVal, list)
      export_json_to_excel({ multiHeader: tHeader1, multiHeader2: tHeader2, data, filename: title, merges })
    })
  }
}


router.beforeEach(async(to, from, next) => {
  //if(to.query.hkCookie){
  localStorage.setItem('cookie',to.query.hKCookie)
    //console.log(getToken('Admin-Token'))
  //}
  // set page title

  // determine whether the user has logged in
 // const hasToken = getToken()

 next()
})

router.afterEach(() => {
})


new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
