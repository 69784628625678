<template>
  <div id="testManagement">
    <div class="el-main">
      <el-form :inline="true" :model="searchFrom"  size="mini" class="demo-form-inline">
        <el-form-item label="渠道">
            <el-select v-model="searchFrom.channel" placeholder="请选择渠道">
              <el-option label="全部" value=""></el-option>
              <el-option label="抖音" value="1"></el-option>
              <el-option label="快手" value="2"></el-option>
              <el-option label="小鹅通" value="3"></el-option>
              <el-option label="视频号" value="4"></el-option>
              <el-option label="商学通" value="5"></el-option>
              <el-option label="枫页小店" value="11"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="达人ID">
          <el-input v-model="searchFrom.expertId" placeholder="请输入达人ID"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button class="download-execl" type="primary" @click="insertTalent">新增达人</el-button>
      <el-table v-loading="loadDataFlag" :data="showDataArray" border style="width: 100%">
        <el-table-column prop="expertId" label="达人ID"></el-table-column>
        <el-table-column prop="expertName" label="达人昵称"></el-table-column>
        <el-table-column label="渠道">
          <template slot-scope="scope">
            <p>{{platformArray[scope.row.channel]}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="operateUserErpId" label="运营者erp账号"></el-table-column>
        <el-table-column prop="operateUserName" label="运营者名称" ></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button type="primary" @click="editFraming(scope.row)" size="small">编辑</el-button>
            <el-button type="danger" @click="deleteFraming(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30]"
          :page-size="pagesize"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="parseInt(total)">
        </el-pagination>
      </div>
    </div>


    <el-dialog :title="isEdit?'修改达人':'新增达人'" :visible.sync="insertOrEdit" @close="closeDialog" style="min-width: 600px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" :validate-on-rule-change="false">
          <el-form-item label="达人ID" prop="expertId" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.expertId"></el-input>
          </el-form-item>
          <el-form-item label="达人名称" prop="expertName" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.expertName"></el-input>
          </el-form-item>
          <el-form-item label="渠道" prop="channel" :label-width="formLabelWidth">
            <el-select v-model="ruleForm.channel" placeholder="请选择活动区域">
              <el-option label="抖音" :value="1"></el-option>
              <el-option label="快手" :value="2"></el-option>
              <el-option label="小鹅通" :value="3"></el-option>
              <el-option label="视频号" :value="4"></el-option>
              <el-option label="商学通" :value="5"></el-option>
              <el-option label="枫页小店" :value="11"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营者" prop="resource" :label-width="formLabelWidth">
            <el-radio-group v-model="ruleForm.resource">
              <div style="width: 100%">
                
                 
                  <el-form-item label="" prop="operateUserErpId">
                    <el-radio @change="chooseYun" :label="1"> 指定运营者：
                  <el-select style="width: 300px;" @change="chooseUser" v-model="ruleForm.operateUserErpId" filterable  placeholder="请选择运营人员">
                    <el-option
                      v-for="(item, index) in userDataList"
                      :key="index"
                      :label="item.department+'-'+item.name+'-'+item.userErpId"
                      :value="item.userErpId">
                    </el-option>
                  </el-select>
                  </el-radio>
                   </el-form-item>
                
               
              </div>              
              <div style="width: 100%; margin-top: 20px;margin-bottom: 20px;">
                <el-radio @change="chooseYun" :label="2" >公有池</el-radio>
              </div>
              <div style="width: 100%">
                <el-form-item label="" prop="operateUserName">
                <el-radio @change="chooseYun" :label="3" >
                  其他
                  <el-input @blur="nameBlurForRule" v-model="ruleForm.operateUserName" ></el-input>
                </el-radio>
                </el-form-item>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInsertForm('ruleForm')">{{isEdit?'修改':'新增'}}</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {getUserList,addTalentMan,searchTalentList,deleteTalent,editTalentMan} from '@/api/index'
  export default{
    data(){
     return {
      isEdit: false,
      ruleForm: {
        expertId: "",
        expertName: "",
        channel: '',
        resource: '',
        operateUserName: ''
      },
      rules: {
        channel: [
          { required: true, message: '请选择渠道', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择配置运营者名称', trigger: 'change' }
        ],
      },
      formLabelWidth: '120px',
      insertOrEdit: false,
      searchFrom: {
        channel: '',
        expertId: ''
      },
      loadDataFlag: true,
      tableData: [],
      platformArray: ['','抖音','快手','小鹅通','视频号','商学通','','','','','','枫页小店'],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      showDataArray: [],
      userDataList: [],
    };
    },
    components:{
    },
    created(){
      this.getUserListData()
      this.loadData()
    },
    methods: {
      loadData(){
        this.loadDataFlag = true
        searchTalentList(this.searchFrom.channel,this.searchFrom.expertId).then(res => {
          this.loadDataFlag = false
          this.tableData = res.data
          this.total = this.tableData.length
          this.showDataArray = []
          this.currentPage = 1
          this.showDataArray = this.tableData.slice(0,this.pagesize)
        })
      },
      chooseYun(){
        console.log(this.ruleForm)
        if(this.ruleForm.resource == 1){
          this.ruleForm.operateUserName = ''
        }else if(this.ruleForm.resource == 3) {
          this.ruleForm.operateUserErpId = ''
        }else {
          this.ruleForm.operateUserName = ''
          this.ruleForm.operateUserErpId = ''
        }
      },
      chooseUser(){
        this.ruleForm.resource = 1
        this.ruleForm.operateUserName = ''
      },
      nameBlurForRule(){
        this.ruleForm.resource = 3
        this.ruleForm.operateUserErpId = ''
      },
      getUserListData(){
        getUserList().then(res=>{
          console.log(res)
          if(res.data){
            this.userDataList = res.data
          }
        })
      },
      closeDialog(){
          console.log(this.$refs)
         this.$refs.ruleForm.resetFields()
      },
      insertTalent(){
        this.insertOrEdit = true
        this.isEdit = false
       /* this.ruleForm = {
          expertId: '',
          expertName: '',
          channel: '',
          resource: '',
          operateUserName: ''
        }*/
        
      },
      onSubmit(){
        this.loadData()
      },
      onSubmitInsertForm(ruleForm){
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            if(this.ruleForm.resource == 1){
              if(this.ruleForm.operateUserErpId === undefined || this.ruleForm.operateUserErpId === null || this.ruleForm.operateUserErpId === ''){
                this.$message.error('请指定运营者！')
                return
              }else {
                this.userDataList.some(item => {
                  if(item.userErpId === this.ruleForm.operateUserErpId){
                    this.ruleForm.gdName = item.name
                  }
                })
                
              }
            }
            if(this.ruleForm.resource == 3){
               if(this.ruleForm.operateUserName === undefined || this.ruleForm.operateUserName === null || this.ruleForm.operateUserName === ''){
                this.$message.error('请填写您已选择的"其他"项的内容！')
                return
              }else {
                this.ruleForm.operateUserErpId = ''
              }
              this.ruleForm.gdName = this.ruleForm.operateUserName
            }
            if(this.ruleForm.resource == 2) {
              this.ruleForm.operateUserName = '公有池'
              this.ruleForm.gdName = '公有池'
            }

            console.log(this.ruleForm)
            if(this.ruleForm.expertId == '' && this.ruleForm.expertName == ''){
              this.$message.error('达人ID和达人昵称至少填写一项')
              return 
            }

            let params = {
              expertId: this.ruleForm.expertId,
              expertName: this.ruleForm.expertName,
              channel: this.ruleForm.channel,
              operateUserName: this.ruleForm.gdName,
              operateUserErpId: this.ruleForm.operateUserErpId
            }
            if(!this.isEdit){
              addTalentMan(params).then(res => {
                if(res.data){
                  this.$message.success('添加成功')
                  this.insertOrEdit = false
                  this.searchFrom.channel = ''
                  this.loadData()
                }else {
                  this.$message.error(res.message)
                }
              })
            }else {
              params.expertId = this.ruleForm.expertId
              params.id = this.ruleForm.id
              editTalentMan(params).then(res => {
                if(res.data){
                  this.$message.success('修改成功')
                  this.insertOrEdit = false
                  this.searchFrom.channel = ''
                  this.loadData()
                }
              })
            }
            



          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      editFraming(row){
          this.isEdit = true
          this.insertOrEdit = true
        this.$nextTick(()=>{
        
        
          this.ruleForm.expertName = row.expertName
          this.ruleForm.expertId = row.expertId
          this.ruleForm.operateUserName = row.operateUserName
          this.ruleForm.operateUserErpId = row.operateUserErpId
          this.ruleForm.channel = row.channel
          this.ruleForm.id = row.id
          if(row.operateUserErpId == null || row.operateUserErpId == '' || row.operateUserErpId == undefined) {
            this.ruleForm.resource = 3
          }else {
            this.ruleForm.resource = 1
            this.ruleForm.operateUserName = ''
          }
          if(row.operateUserName == '公有池'){
            this.ruleForm.resource = 2
            this.ruleForm.operateUserName = ''
          }
        })
        console.log(row)
      },
      deleteFraming(row){
        this.$confirm('此操作将删除该达人的信息数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            id: row.id
          }
          deleteTalent(params).then(res=>{
            console.log(res)
            if(res.data){
              this.$message.success('删除成功！')
              this.loadData()
            }
          })
        }).catch(() => {
          this.$message({
              type: 'info',
              message: '已取消删除'
          });          
        });
      },
      handleSizeChange(val){
        this.pagesize = val;
        this.showDataArray = []
        this.showDataArray = this.tableData.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)
      },
      handleCurrentChange(val){
        this.showDataArray = []
        this.currentPage = val;
        this.showDataArray = this.tableData.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)
      }
    },
  }
</script>

<style>
.download-execl {
    position: absolute;
    top: 75px;
    right: 40px;
}

.el-dialog {
  min-width: 600px;
}
</style>
