<template>
  <div id="testManagement">
    <el-button style="margin-top: 30px;" type="primary" class="add-keys" @click="addKeys">添加刷单关键词</el-button>
    <div class="el-main">
      <el-table v-loading="loadDataFlag" :data="tableData" border style="width: 100%">
        <el-table-column prop="text" label="名称"></el-table-column>
        <el-table-column label="创建时间">
           <template slot-scope="scope">
             <p>{{new Date(scope.row.gmtCreate).pattern('yyyy-MM-dd HH:mm:ss')}}</p>
           </template> 
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                 <el-button type="danger" @click="deleteFraming(scope.row)" size="small">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>


    <el-dialog title="添加刷单关键词" :visible.sync="insertOrEdit">
        <el-form :model="form">
            <el-form-item label="刷单关键词" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="insertOrEdit = false">取 消</el-button>
            <el-button type="primary" @click="addKeysFrom">添 加</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
Date.prototype.pattern=function(fmt) {         
    var o = {         
    "M+" : this.getMonth()+1, //月份         
    "d+" : this.getDate(), //日         
    "h+" : this.getHours()%12 == 0 ? 12 : this.getHours()%12, //小时         
    "H+" : this.getHours(), //小时         
    "m+" : this.getMinutes(), //分         
    "s+" : this.getSeconds(), //秒         
    "q+" : Math.floor((this.getMonth()+3)/3), //季度         
    "S" : this.getMilliseconds() //毫秒         
    };         
    var week = {         
    "0" : "/u65e5",         
    "1" : "/u4e00",         
    "2" : "/u4e8c",         
    "3" : "/u4e09",         
    "4" : "/u56db",         
    "5" : "/u4e94",         
    "6" : "/u516d"        
    };         
    if(/(y+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));         
    }         
    if(/(E+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "/u661f/u671f" : "/u5468") : "")+week[this.getDay()+""]);         
    }         
    for(var k in o){         
        if(new RegExp("("+ k +")").test(fmt)){         
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));         
        }         
    }         
    return fmt;         
};
import {deleteKeys,addKeys,searchKeysList} from '@/api/index'
  export default{
    data(){
     return {
      form: {},
      formLabelWidth: '120px',
      insertOrEdit: false,
      insertOrEditTile: '新增达人',
      searchFrom: {
        user: '',
        region: ''
      },
      loadDataFlag: false,
      tableData: [
      ],
      orderStatusArray: ['未支付','已支付','已退款'],
      orderTypeArray: ['未分类','线上','线上交付','线下'],
      orderClassificationArray: ['免费','付费'],
      sourceArray: ['短视频','直播','自然流量'],
      platformArray: ['','抖音','快手','小鹅通','视频号','商学通'],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      showDataArray: [], 
    };
    },
    components:{
    },
    created(){
        this.loadData()
    },
    methods: {
        loadData(){
            searchKeysList().then(res => {
                if(res.data) {
                    this.tableData = res.data
                }
            })
        },
        addKeys(){
            this.insertOrEdit = true
            this.form = {}
        },
        insertTalent(){
            this.insertOrEdit = true
        },
        onSubmit(){

        },
        //添加关键字
        addKeysFrom(){
            let params = {
                text: this.form.name
            }
            addKeys(params).then(res => {
                if(res.data){
                    this.insertOrEdit = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    this.loadData()
                }
            })
        },
        //删除关键字
        deleteFraming(row){
            this.$confirm('此操作将删除该刷单关键词, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    id: row.id
                }
                deleteKeys(params).then( res =>{
                    if(res.data){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.loadData()
                    }
                   
                })                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        }
    },
  }
</script>

<style>
</style>
