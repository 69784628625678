<template>
  <div class="wrapper">
    <el-date-picker
      class="date-picker-class"
      v-model="dataValue"
      @change="changeTime"
      :picker-options="pickerOptions"
      value-format="yyyy-MM-dd"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
    <el-button class="download-execl" type="primary" @click="exportExcel">导出excel</el-button>
    <el-table
      :data="tableData"
      v-loading="loadDataFlag"
      style="width: 100%"
      align="center"
      :cell-style="columStyle"
      :header-cell-style="headerColumStyle"
      :row-style="changeRowBgColorByIsPay"
      border
    >
      <el-table-column prop="date" :label="titleObject.firstLabel" align="center">
        <el-table-column prop="departmentName" label="部门" align="center"> </el-table-column>
        <el-table-column prop="operateUserName" label="运营者" align="center"> </el-table-column>
        <el-table-column prop="channelName" label="渠道" align="center">
          <!-- <template slot-scope="scope">
            <p>{{channelArray[scope.row.channel]}}</p>
          </template> -->
        </el-table-column>
        <el-table-column prop="expertName" label="达人昵称" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.secordLabel" align="center">
        <el-table-column prop="todayFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="todayChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="todayTotalCount" label="日单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.thridLabel" align="center">
        <el-table-column prop="lastMonthTodayFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTodayChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTodayTotalCount" label="日单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.fourthLabel" align="center">
        <el-table-column prop="monthTotalFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="monthTotalChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="monthTotalCount" label="月单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.fivethLabel" align="center">
       <el-table-column prop="lastMonthTotalFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTotalChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTotalCount" label="月单合计" align="center"> </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getDetailDatas} from '@/api/index'
export default {
  data() {
    return {
      channelArray: ['','抖店','快手','小鹅通','视频号','商学通'],
      pickerOptions: {
         disabledDate (time) {
            //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
            return time.getTime() > Date.now()//选当前时间之前的时间
            //return time.getTime() < Date.now()//选当前时间之后的时间
          }
      },
      dataValue: '',
      loadDataFlag: true,
      titleObject:{
        firstLabel: '运营者单量统计',
        secordLabel: '',
        thridLabel: '',
        fourthLabel: '',
        fivethLabel: '',
      },
      tableData: [],
      newColumns: [
      {
          "prop": "depart",
          "label": "运营者单量统计",
          "align": "center",
          "is_co": 4,
          "children": [
              {
                  "label": "部门",
                  "prop": "departmentName",
                  "is_co": 2,
                  "align": "center"
              },
              {
                  "label": "运营者",
                  "prop": "operateUserName",
                  "is_co": 2,
                  "align": "center"
              },
              {
                  "label": "渠道",
                  "prop": "channelName",
                  "is_co": 2,
                  "align": "center"
              },
              {
                  "label": "达人昵称",
                  "prop": "expertName",
                  "width": "150",
                  "is_co": 2,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "todayFreeCount",
          "label": '',//this.titleObject.secordLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "todayFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "todayChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "日单合计",
                  "prop": "todayTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "lastMonthTodayFreeCount",
          "label": '',//this.titleObject.thridLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "lastMonthTodayFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "lastMonthTodayChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "日单合计",
                  "prop": "lastMonthTodayTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "monthTotalFreeCount",
          "label": '',//this.title.fourthLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "monthTotalFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "monthTotalChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "月单合计",
                  "prop": "monthTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "lastMonthTotalFreeCount",
          "label": '',//this.titleObject.fivethLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "lastMonthTotalFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "lastMonthTotalChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "月单合计",
                  "prop": "lastMonthTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      }
      ]
    };
  },
  props: {},
  created(){
    var nowday = new Date();
    nowday.setDate(nowday.getDate()-1);
    this.dataValue = nowday.pattern('yyyy-MM-dd')
    this.fillLable()
    this.loadData()
  },
  methods: {
    changeTime(){
      this.loadDataFlag = true
      if(this.dataValue === null) {
        var nowday = new Date();
        nowday.setDate(nowday.getDate()-1);
        this.dataValue = nowday.pattern('yyyy-MM-dd')
      }
      this.loadData()
      this.fillLable()
    },
    loadData(){
      getDetailDatas(this.dataValue).then(res=>{
        this.loadDataFlag = false
        let list = res.data
        this.tableData = []
        list.some(item => {
          let obj = {
            lastMonthTodayChargeCount: item.lastMonthTodayChargeCount,
            lastMonthTodayFreeCount: item.lastMonthTodayFreeCount,
            lastMonthTodayTotalCount: item.lastMonthTodayTotalCount,
            lastMonthTotalChargeCount: item.lastMonthTotalChargeCount,
            lastMonthTotalCount: item.lastMonthTotalCount,
            lastMonthTotalFreeCount: item.lastMonthTotalFreeCount,
            monthTotalChargeCount: item.monthTotalChargeCount,
            monthTotalCount: item.monthTotalCount,
            monthTotalFreeCount: item.monthTotalFreeCount,
            operateUserErpId: item.operateUserErpId,
            operateUserName: item.operateUserName,
            todayChargeCount: item.todayChargeCount,
            todayFreeCount: item.todayFreeCount,
            todayTotalCount: item.todayTotalCount,
            departmentName: item.departmentName + ' 合计',
            heji: true
          }
          if(item.accountVolist&&item.accountVolist.length>0){
            for(var i=0;i<item.accountVolist.length;i++){
              item.accountVolist[i].departmentName = item.departmentName
              item.accountVolist[i].channelName = this.channelArray[item.accountVolist[i].channel]
              this.tableData.push(item.accountVolist[i])
            }
          }
          this.tableData.push(obj)
        })
      })
      
    },
    fillLable(){
      let nowData = new Date(this.dataValue)
      let year = nowData.getFullYear()
      let month = (nowData.getMonth() + 1)
      let day = nowData.getDate()
      this.titleObject.secordLabel = month +'月' + day + '日'
      this.titleObject.fourthLabel = month + '月累计'


      var year2 = year;
      var month2 = parseInt(month) - 1;
      if (month2 == 0) {
          year2 = parseInt(year2) - 1;
          month2 = 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
          day2 = days2;
      }

      this.titleObject.thridLabel = month2 + '月'+day2+'日'
      this.titleObject.fivethLabel = month2 + '月同期'

      this.newColumns[1].label = this.titleObject.secordLabel
      this.newColumns[2].label = this.titleObject.thridLabel
      this.newColumns[3].label = this.titleObject.fourthLabel
      this.newColumns[4].label = this.titleObject.fivethLabel


    },
    exportExcel() {      
      let tHeader1 = [];
      let tHeader2 = [];
      let propArr = [];
      var columns=this.newColumns
      var data=this.tableData
      
      for (var i = 0; i < columns.length; i++) {
          if (columns[i].children) {
              tHeader1.push(columns[i].label)
              for (var j = 0; j < columns[i].children.length; j++) {
                  tHeader2.push(columns[i].children[j].label);
                  propArr.push(columns[i].children[j].prop);
                  if (j > 0) {
                      tHeader1.push('');
                  }
              }
          }else{
              tHeader1.push('')
              tHeader2.push(columns[i].label)
              propArr.push(columns[i].prop)
          }
      }
      //return
      //合并单元格
      let merges = [
          "A1:D1",
          "E1:G1",
          "H1:J1",
          "K1:M1",
          "N1:P1",
      ];
      if(this.dataValue === null){
        var nowday = new Date();
        nowday.setDate(nowday.getDate()-1);
        this.dataValue = nowday.pattern('yyyy-MM-dd')
      }
      var filename = this.titleObject.secordLabel+'账户单量统计';
      var downEx = new this.ExportExcel2(data, tHeader2, tHeader1, merges, propArr, filename);
      downEx.export2Excel2();
    },  
    changeRowBgColorByIsPay(row){
      if(row.row.heji) {
        let styleJson = {
          'background-color': '#bdd7ee',
          'color': '#27408B'  
        }
        return styleJson
      }
    },
    columStyle({row, columnIndex }) {
      if (
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 6 ||
        columnIndex == 10 ||
        columnIndex == 11 ||
        columnIndex == 12
      ) {
        if(!row.heji){
          return 'background: #fff2cd;';
        }
      }
    },
    headerColumStyle({ row, columnIndex }) {
      if (row.length == 5) {
        if (columnIndex == 1 || columnIndex == 3) {
          return 'background: #fff2cd';
        }
      }
      if (row.length == 16) {
         return 'background: #f4af85;color: #27408B;font-weight: bold;';
      }
    },
    onSubmit() {},
  },
};
</script>
<style scoped>
.download-execl {
  position: absolute;
  right: 50px;
  top: 75px;
  z-index: 99999;
}
.date-picker-class {
  position: absolute;
  right: 250px;
  top: 75px;
  z-index: 99999;
}
</style>
