<template>
  <div class="wrapper">
    <el-date-picker
      class="date-picker-class"
      v-model="dataValue"
      @change="changeTime"
      :picker-options="pickerOptions"
      value-format="yyyy-MM-dd"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
    <el-button class="download-execl" type="primary" @click="exportExcel">导出excel</el-button>
    <el-table
      :data="tableData"
      v-loading="loadDataFlag"
      style="width: 100%"
      align="center"
      :cell-style="columStyle"
      :header-cell-style="headerColumStyle"
      :row-style="changeRowBgColorByIsPay"
      border
    >
      <el-table-column >
        <el-table-column prop="departmentName" label="部门" align="center"> </el-table-column>
        <el-table-column prop="operateUserName" label="运营者" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.secordLabel" align="center">
        <el-table-column prop="todayFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="todayChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="todayTotalCount" label="日单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.thridLabel" align="center">
        <el-table-column prop="lastMonthTodayFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTodayChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTodayTotalCount" label="日单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.fourthLabel" align="center">
        <el-table-column prop="monthTotalFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="monthTotalChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="monthTotalCount" label="月单合计" align="center"> </el-table-column>
      </el-table-column>
      <el-table-column :label="titleObject.fivethLabel" align="center">
       <el-table-column prop="lastMonthTotalFreeCount" label="免费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTotalChargeCount" label="付费" align="center"> </el-table-column>
        <el-table-column prop="lastMonthTotalCount" label="月单合计" align="center"> </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getDaysData} from "@/api/index.js"
Date.prototype.pattern=function(fmt) {         
    var o = {         
    "M+" : this.getMonth()+1, //月份         
    "d+" : this.getDate(), //日         
    "h+" : this.getHours()%12 == 0 ? 12 : this.getHours()%12, //小时         
    "H+" : this.getHours(), //小时         
    "m+" : this.getMinutes(), //分         
    "s+" : this.getSeconds(), //秒         
    "q+" : Math.floor((this.getMonth()+3)/3), //季度         
    "S" : this.getMilliseconds() //毫秒         
    };         
    var week = {         
    "0" : "/u65e5",         
    "1" : "/u4e00",         
    "2" : "/u4e8c",         
    "3" : "/u4e09",         
    "4" : "/u56db",         
    "5" : "/u4e94",         
    "6" : "/u516d"        
    };         
    if(/(y+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));         
    }         
    if(/(E+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "/u661f/u671f" : "/u5468") : "")+week[this.getDay()+""]);         
    }         
    for(var k in o){         
        if(new RegExp("("+ k +")").test(fmt)){         
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));         
        }         
    }         
    return fmt;         
};
export default {
  data() {
    return {
      pickerOptions: {
         disabledDate (time) {
            //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
            return time.getTime() > Date.now()//选当前时间之前的时间
            //return time.getTime() < Date.now()//选当前时间之后的时间
          }
      },
      dataValue: '',
      loadDataFlag: true,
      titleObject:{
        firstLabel: '',
        secordLabel: '',
        thridLabel: '',
        fourthLabel: '',
        fivethLabel: '',
      },
      tableData: [],
      newColumns: [
      {
          "prop": "",
          "label": '',//this.titleObject.firstLabel,
          "align": "center",
          "is_co": 2,
          "children": [
              {
                  "label": "部门",
                  "prop": "departmentName",
                  "is_co": 2,
                  "align": "center"
              },
              {
                  "label": "运营者",
                  "prop": "operateUserName",
                  "is_co": 2,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "todayFreeCount",
          "label": '',//this.titleObject.secordLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "todayFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "todayChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "日单合计",
                  "prop": "todayTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "lastMonthTodayFreeCount",
          "label": '',//this.titleObject.thridLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "lastMonthTodayFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "lastMonthTodayChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "日单合计",
                  "prop": "lastMonthTodayTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "monthTotalFreeCount",
          "label": '',//this.title.fourthLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "monthTotalFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "monthTotalChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "月单合计",
                  "prop": "monthTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      },
      {
          "prop": "lastMonthTotalFreeCount",
          "label": '',//this.titleObject.fivethLabel,
          "align": "center",
          "is_co": 3,
          "children": [
              {
                  "label": "免费",
                  "prop": "lastMonthTotalFreeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "付费",
                  "prop": "lastMonthTotalChargeCount",
                  "is_co": 3,
                  "align": "center"
              },
              {
                  "label": "月单合计",
                  "prop": "lastMonthTotalCount",
                  "is_co": 3,
                  "align": "center"
              }
          ]
      }
      ],
    };
  },
  props: {},
  created(){
    var nowday = new Date();
    nowday.setDate(nowday.getDate()-1);
    this.dataValue = nowday.pattern('yyyy-MM-dd')
    this.fillLable()
    this.loadData()
  },
  methods: {
    changeTime(){
      this.loadDataFlag = true
      if(this.dataValue === null) {
        var nowday = new Date();
        nowday.setDate(nowday.getDate()-1);
        this.dataValue = nowday.pattern('yyyy-MM-dd')
      }
      this.loadData()
      this.fillLable()
    },
    loadData(){
      // let params = '2022-05-01'
      getDaysData(this.dataValue).then(res=>{
        let datas = res.data
        this.loadDataFlag = false
        this.tableData = []
        datas.some(item => {
          let obj = {
            lastMonthTodayChargeCount: item.lastMonthTodayChargeCount,
            lastMonthTodayFreeCount: item.lastMonthTodayFreeCount,
            lastMonthTodayTotalCount: item.lastMonthTodayTotalCount,
            lastMonthTotalChargeCount: item.lastMonthTotalChargeCount,
            lastMonthTotalCount: item.lastMonthTotalCount,
            lastMonthTotalFreeCount: item.lastMonthTotalFreeCount,
            monthTotalChargeCount: item.monthTotalChargeCount,
            monthTotalCount: item.monthTotalCount,
            monthTotalFreeCount: item.monthTotalFreeCount,
            operateUserErpId: item.operateUserErpId,
            operateUserName: item.operateUserName,
            todayChargeCount: item.todayChargeCount,
            todayFreeCount: item.todayFreeCount,
            todayTotalCount: item.todayTotalCount,
            departmentName: item.departmentName + ' 合计',
            heji: true
          }
          if(item.userVolist&&item.userVolist.length>0){
            for(var i=0;i<item.userVolist.length;i++){
              item.userVolist[i].departmentName = item.departmentName
              this.tableData.push(item.userVolist[i])
            }
          }
          this.tableData.push(obj)
        })
      })
      
    },
    fillLable(){
      

      let nowData = new Date(this.dataValue)
      let year = nowData.getFullYear()
      let month = (nowData.getMonth() + 1)
      let day = nowData.getDate()
      this.titleObject.secordLabel = month +'月' + day + '日'
      this.titleObject.fourthLabel = month + '月累计'


      var year2 = year;
      var month2 = parseInt(month) - 1;
      if (month2 == 0) {
          year2 = parseInt(year2) - 1;
          month2 = 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
          day2 = days2;
      }

      this.titleObject.thridLabel = month2 + '月'+day2+'日'
      this.titleObject.fivethLabel = month2 + '月同期'

      this.newColumns[1].label = this.titleObject.secordLabel
      this.newColumns[2].label = this.titleObject.thridLabel
      this.newColumns[3].label = this.titleObject.fourthLabel
      this.newColumns[4].label = this.titleObject.fivethLabel


    },
    exportExcel() {      
      let tHeader1 = [];
      let tHeader2 = [];
      let propArr = [];
      var columns=this.newColumns
      var data=this.tableData
      
      for (var i = 0; i < columns.length; i++) {
          if (columns[i].children) {
              tHeader1.push(columns[i].label)
              for (var j = 0; j < columns[i].children.length; j++) {
                  tHeader2.push(columns[i].children[j].label);
                  propArr.push(columns[i].children[j].prop);
                  if (j > 0) {
                      tHeader1.push('');
                  }
              }
          }else{
              tHeader1.push('')
              tHeader2.push(columns[i].label)
              propArr.push(columns[i].prop)
          }
      }
      //return
      //合并单元格
      let merges = [
          "A1:B1",
          "C1:E1",
          "F1:H1",
          "I1:K1",
          "L1:N1",
      ];
      if(this.dataValue === null){
        var nowday = new Date();
        nowday.setDate(nowday.getDate()-1);
        this.dataValue = nowday.pattern('yyyy-MM-dd')
      }
      var filename = this.titleObject.secordLabel+'单量统计';
      var downEx = new this.ExportExcel2(data, tHeader2, tHeader1, merges, propArr, filename);
      downEx.export2Excel2();
    },  
    changeRowBgColorByIsPay(row){
      if(row.row.heji) {
        let styleJson = {
          'background-color': '#bdd7ee',
          'color': '#27408B'  
        }
        return styleJson
      }
    },
    columStyle({row, columnIndex }) {
      if (
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 8 ||
        columnIndex == 9 ||
        columnIndex == 10
      ) {
        if(!row.heji){
          return 'background: #fff2cd';
        }
      }
    },
    headerColumStyle({ row, columnIndex }) {
      if (row.length == 5) {
        if(columnIndex === 1){
          row.label = this.titleObject.firstLabel
        }
        if (columnIndex == 1 || columnIndex == 3) {
          return 'background: #fff2cd';
        }
      }
      if (row.length == 14) {
        return 'background: #f4af85;color: #27408B;font-weight: bold;';
      }
    },
    onSubmit() {},
  },
};
</script>
<style scoped>
.download-execl {
  position: absolute;
  right: 50px;
  top: 75px;
  z-index: 99999;
}
.date-picker-class {
  position: absolute;
  right: 250px;
  top: 75px;
  z-index: 99999;
}
</style>
