import request from '@/utils/request'
export function getDaysData(params){
    return request({
        url: `/statistics/operateUser?date=${params}`,
        method: 'get'
    })
}

export function getDetailDatas(params){
    return request({
        url: `/statistics/operateAccount?date=${params}`,
        method: 'get'
    })
}

export function getOrderList(dataValue,source,platform){
    return request({
        url: `/order/orderList?date=${dataValue}&source=${source}&platform=${platform}`,
        method: 'get'
    })
}

export function login(data){
    return request({
        url: `/login`,
        method: 'post',
        data,
    })
}

export function deleteKeys(data){
    return request({
        url: '/clickFarming/deleteClickFarming',
        method: 'post',
        data
    })
}

export function addKeys(data){
    return request({
        url: '/clickFarming/addClickFarming',
        method: 'post',
        data
    })
}

export function searchKeysList(){
    return request({
        url: '/clickFarming/clickFarmingList',
        method: 'get'
    })
}



//达人
//获取员工数据
export function getUserList(){
    return request({
        url: '/expertOperate/employesList',
        method: 'get'
    })
}

// 新增达人
export function addTalentMan(data){
    return request({
        url: '/expertOperate/addExpertOperation',
        method: 'post',
        data
    })
}

export function searchTalentList(data,expertId){
    return request({
        url: `/expertOperate/expertOperateList?channel=${data}&expertId=${expertId}`,
        method: 'get'
    })
}

export function deleteTalent(data){
    return request({
        url: `/expertOperate/deleteExpertOperation`,
        method: 'post',
        data
    })
}

export function editTalentMan(data){
    return request({
        url: '/expertOperate/updateExpertOperation',
        method: 'post',
        data
    })
}