<template>
  <div id="testManagement">
    <div class="el-main">
      <el-date-picker
        v-model="dataValue"
        @change="changeTime"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        align="right"
        type="date"
        placeholder="选择日期"
        style="margin-bottom: 20px; width: 140px;"
        size="mini"
      >
      </el-date-picker>
      <span class="new-liul">流量来源：</span>
      <el-select size="mini" style="width: 100px;" @change="changeOrderType" v-model="orderSource" placeholder="请选择活动区域">
        <el-option label="全部" value=""></el-option>
        <el-option label="短视频" value="0"></el-option>
        <el-option label="直播" value="1"></el-option>
        <el-option label="自然流量" value="2"></el-option>
      </el-select>
      <span class="new-liul">渠道：</span>
      <el-select size="mini"  style="width: 100px;" @change="changePlatFrom" v-model="platFrom" placeholder="请选择活动区域">
        <el-option label="全部" value=""></el-option>
        <el-option label="抖音" value="1"></el-option>
        <el-option label="快手" value="2"></el-option>
        <el-option label="小鹅通" value="3"></el-option>
        <el-option label="视频号" value="4"></el-option>
        <el-option label="商学通" value="5"></el-option>
        <el-option label="枫页小店" value="11"></el-option>
      </el-select>
      <el-button class="download-execl" type="primary" @click="exportExcel">导出excel</el-button>
      <el-table v-loading="loadDataFlag" :data="showDataArray" border style="width: 100%">
        <el-table-column prop="shopName" label="店铺"></el-table-column>
        <el-table-column prop="orderNo" label="订单编号" width="190"></el-table-column>
        <el-table-column prop="productName" label="商品名称"  width="350"></el-table-column>
        <el-table-column prop="productId" label="商品ID" width="140"></el-table-column>
        <el-table-column prop="productPrice" label="商品金额"></el-table-column>
        <el-table-column prop="payDateTime" label="支付完成时间"  width="100"></el-table-column>
        <el-table-column prop="orderStatusDesc" label="订单状态"></el-table-column>
        <el-table-column prop="afterSalesStatus" label="售后状态"></el-table-column>
        <el-table-column prop="payAmount" label="订单应付金额"></el-table-column>
        <el-table-column prop="expertId" label="达人ID"></el-table-column>
        <el-table-column prop="expertName" label="达人昵称"></el-table-column>
        <el-table-column prop="adChannel" label="广告渠道"></el-table-column>
        <el-table-column prop="orderTypeTitle" label="订单类型"></el-table-column>
        <el-table-column prop="platformTitle" label="渠道"></el-table-column>
        <el-table-column prop="operator" label="运营人员"></el-table-column>
        <el-table-column prop="department" label="部门"></el-table-column>
        <el-table-column prop="teacher" label="老师"></el-table-column>
        <el-table-column prop="isClickFarmingTitle" label="是否刷单"></el-table-column>
        <el-table-column prop="orderStatusTitle" label="订单状态"></el-table-column>
        <el-table-column prop="orderClassificationTitle" label="订单分类"></el-table-column>
        <el-table-column prop="sourceTitle" label="流量来源"></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30]"
          :page-size="pagesize"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="parseInt(total)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {getOrderList} from '@/api/index'
Date.prototype.pattern=function(fmt) {         
    var o = {         
    "M+" : this.getMonth()+1, //月份         
    "d+" : this.getDate(), //日         
    "h+" : this.getHours()%12 == 0 ? 12 : this.getHours()%12, //小时         
    "H+" : this.getHours(), //小时         
    "m+" : this.getMinutes(), //分         
    "s+" : this.getSeconds(), //秒         
    "q+" : Math.floor((this.getMonth()+3)/3), //季度         
    "S" : this.getMilliseconds() //毫秒         
    };         
    var week = {         
    "0" : "/u65e5",         
    "1" : "/u4e00",         
    "2" : "/u4e8c",         
    "3" : "/u4e09",         
    "4" : "/u56db",         
    "5" : "/u4e94",         
    "6" : "/u516d"        
    };         
    if(/(y+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));         
    }         
    if(/(E+)/.test(fmt)){         
        fmt=fmt.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "/u661f/u671f" : "/u5468") : "")+week[this.getDay()+""]);         
    }         
    for(var k in o){         
        if(new RegExp("("+ k +")").test(fmt)){         
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));         
        }         
    }         
    return fmt;         
};
  export default{
    data(){
     return {
      platFrom: '',
      orderSource: '',
      pickerOptions: {
         disabledDate (time) {
            return time.getTime() > Date.now()
          }
      },
      loadDataFlag: true,
      dataValue: '',
      tableData: [],
      orderStatusArray: ['未支付','已支付','已退款'],
      orderTypeArray: ['未分类','线上','线上交付','线下'],
      orderClassificationArray: ['免费','付费'],
      sourceArray: ['短视频','直播','自然流量'],
      platformArray: ['','抖音','快手','小鹅通','视频号','商学通','','','','','','枫页小店'],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      showDataArray: [],
      newColumns: [
      {
        "prop": "shopName",
        "label": '店铺',
        "align": "center"
      },
      {
        "prop": "orderNo",
        "label": '订单编号',
        "align": "center"
      },
      {
        "prop": "productName",
        "label": '商品名称',
        "align": "center"
      },
      {
        "prop": "productId",
        "label": '商品ID',
        "align": "center"
      },
      {
        "prop": "productPrice",
        "label": '商品金额',
        "align": "center"
      },
      {
        "prop": "payDateTime",
        "label": '支付完成时间',
        "align": "center"
      },
      {
        "prop": "orderStatusDesc",
        "label": '订单状态',
        "align": "center"
      },
      {
        "prop": "afterSalesStatus",
        "label": '售后状态',
        "align": "center"
      },
      {
        "prop": "payAmount",
        "label": '订单应付金额',
        "align": "center"
      },
      {
        "prop": "expertId",
        "label": '达人ID',
        "align": "center"
      },
      {
        "prop": "expertName",
        "label": '达人昵称',
        "align": "center"
      },
      {
        "prop": "adChannel",
        "label": '广告渠道',
        "align": "center"
      },
      {
        "prop": "orderTypeTitle",
        "label": '订单类型',
        "align": "center"
      },
      {
        "prop": "platformTitle",
        "label": '渠道',
        "align": "center"
      },
      {
        "prop": "operator",
        "label": '运营人员',
        "align": "center"
      },
      {
        "prop": "department",
        "label": '部门',
        "align": "center"
      },
      {
        "prop": "teacher",
        "label": '老师',
        "align": "center"
      },
      {
        "prop": "orderStatusTitle",
        "label": '订单状态',
        "align": "center"
      },
      {
        "prop":"isClickFarmingTitle",
        "label": "是否刷单",
        "align": "center"
      },
      {
        "prop": "orderClassificationTitle",
        "label": '订单分类',
        "align": "center"
      },
      {
        "prop": "sourceTitle",
        "label": '流量来源',
        "align": "center"
      }
      ],
    };
    },
    components:{
    },
    created(){
      var nowday = new Date();
      nowday.setDate(nowday.getDate()-1);
      this.dataValue = nowday.pattern('yyyy-MM-dd')
      this.loadData()
    },
    methods: {
      loadData(){
        getOrderList(this.dataValue,this.orderSource,this.platFrom).then(res => {
          this.loadDataFlag = false
          this.tableData = res.data
          this.total = res.data.length
          this.tableData.some(item => {
            item.orderStatusTitle = this.orderStatusArray[item.orderStatus]
            item.orderTypeTitle = this.orderTypeArray[item.orderType]
            item.orderClassificationTitle = this.orderClassificationArray[item.orderClassification]
            item.sourceTitle = this.sourceArray[item.source]
            item.platformTitle = this.platformArray[item.platform]
            item.isClickFarmingTitle = item.isClickFarming === 1?'刷单':'非刷单' 
            let changeDate = new Date(item.payDateTime)
            item.payDateTime = item.payDateTime === null? '---':changeDate.pattern('yyyy-MM-dd HH:mm:ss')
          })
          this.showDataArray = []
          this.currentPage = 1
          this.showDataArray = this.tableData.slice(0,this.pagesize)
        })
      },
      changePlatFrom(){
        this.loadDataFlag = true
        this.loadData()
      },
      changeOrderType(){
        this.loadDataFlag = true
        this.loadData()
      },
      changeTime(){
        this.loadDataFlag = true
        if(this.dataValue === null){
          var nowday = new Date();
          nowday.setDate(nowday.getDate()-1);
          this.dataValue = nowday.pattern('yyyy-MM-dd')
        }
        this.loadData()
      },
      handleSizeChange(val){
        this.pagesize = val;
        this.showDataArray = []
        this.showDataArray = this.tableData.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)
      },
      handleCurrentChange(val){
        this.showDataArray = []
        this.currentPage = val;
        this.showDataArray = this.tableData.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)
      },
      exportExcel() {      
        let tHeader1 = [];
        let tHeader2 = [];
        let propArr = [];
        var columns=this.newColumns
        var data=this.tableData
        
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].children) {
                tHeader1.push(columns[i].label)
                for (var j = 0; j < columns[i].children.length; j++) {
                    tHeader2.push(columns[i].children[j].label);
                    propArr.push(columns[i].children[j].prop);
                    if (j > 0) {
                        tHeader1.push('');
                    }
                }
            }else{
                tHeader1 = []
                tHeader2.push(columns[i].label)
                propArr.push(columns[i].prop)
            }
        }

        //合并单元格
        let merges = [];
        if(this.dataValue === null){
          var nowday = new Date();
          nowday.setDate(nowday.getDate()-1);
          this.dataValue = nowday.pattern('yyyy-MM-dd')
        }
        var filename = this.dataValue+'订单统计';
        var downEx = new this.ExportExcel2(data, tHeader2, tHeader1, merges, propArr, filename);
        downEx.export2Excel2();
      },  
    },
  }
</script>

<style>
  .el-tabs{
    width: 100%;
  }
  .el-header {
    line-height: 60px;
    font-size: 28px;
  }

  .el-main {
    width: 100%;
    min-height: 500px;
    text-align: left;
    margin-top: 20px;
  }

  .page {
    margin-top: 20px;
    text-align: right;
  }

  .download-execl {
    float: right;
  }

  .new-liul {
    margin-left: 20px;
  }
</style>
