import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import searchData from '@/pages/searchData';
import dayDatas from '@/pages/dayDatas';
import detailDatas from '@/pages/detailDatas';
import LoginAdmin from '@/views/LoginAdmin';
import talentManage from '@/pages/talentManage'
import farmingKey from '@/pages/farmingKey'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name: '日报统计查询',
        path: 'manage',
        component: searchData
      },
      {
        name: '运营者单量统计',
        path: 'dayDatas',
        component: dayDatas
      },
      {
        name: '运营者账户单量统计',
        path: 'detailDatas',
        component: detailDatas
      },
      {
        name: '达人管理',
        path: 'talentManage',
        component: talentManage
      },
      {
        name: '刷单关键词管理',
        path:'farmingKey',
        component: farmingKey
      }
    ]
  },
  {
    path: '*',
    redirect: '/manage'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginAdmin,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
