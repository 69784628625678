<template>
  <div class="warpper-login">
    <div class="login-block">
      <p class="login-p">获客--财务查询统计工具平台</p>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
       
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 260px;" type="primary" @click="submitForm('ruleForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {login} from '@/api/index'
export default {
  name: 'LoginAdmin',
   data() {
    var checkName=(rule,value,callback)=>{
      if(value===''){
        return callback(new Error('请输入账号'));
      }
      // else if(value!=='admin'){
      //    return callback(new Error('账号错误'));
      // }
      else{
        callback();
      }
    }

   var checkPassword=(rule,value,callback)=>{
    if(value===''){
        return callback(new Error('请输入密码'));
      }
      // else if(value!=='admin1234'){
      //    return callback(new Error('密码错误'));
      // }
      else{
        callback();
      }
    }
    return {
      ruleForm: {
        name: '',
        password: '',
      },
      
      rules: {
        name: [
          { validator:checkName,required: true, trigger: 'blur' }
        ],
        password: [
          { validator:checkPassword,required: true, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {name,password}=this.ruleForm;
          const res=await login({userName:name,passWord:password});
          console.log(res,444)
          if(res.loginStatus===0){
            this.$router.push({path:'/'})
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
}
</script>
<style>
.warpper-login {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-image: url("@/assets/bg.jpg");
  background-size: 100%;
}

.login-block {
  width: 360px;
  height: 300px;
  padding-right: 60px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -150px 0 0 -200px;
  background: #fff;
}

.login-p {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-size: 20px;
  padding-left: 20px;
}
</style>
